import './product-switch.scss';

// import { Slider } from './../../components/slider/slider';
import { A11y, Navigation } from 'swiper';

class ProductSwitch {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-productswitch',
            sliderAttr: 'slider',
            switchAttr: 'switch'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$productSwitch = $element;
        this.$productSwitchSlider = this.$productSwitch.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.sliderAttr + '"]');

        this.sliderActive = false;

        if (this.$productSwitchSlider.length > 1) {
            import(
                /* webpackChunkName: "slider/slider.js" */
                './../../components/slider/slider').then(({ Slider }) => {
                this.Slider = Slider;
                this.initialize();
            });
        }
        // this.initialize();
    }

    initialize () {
        console.log('ProductSwitch');
        for (let i = 0; i < this.$productSwitchSlider.length; i++) {
            const $productSlider = this.$productSwitchSlider[i];
            this.initSlider($productSlider);
        }
    }

    initSlider ($slider) {
        this.productSwitchSlider = new this.Slider($slider, {
            modules: [A11y, Navigation],
            speed: 500,
            slidesPerView: 'auto',
            centeredSlides: true,
            centeredSlidesBounds: true,
            initAttr: this.settings.initAttr
        });

        this.sliderActive = true;
    }
}

export { ProductSwitch };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$productSwitch = $context.querySelectorAll('[data-productswitch="root"]');
        for (let i = 0; i < $$productSwitch.length; i++) {
            const $productSwitch = $$productSwitch[i];

            const productSwitchAPI = new ProductSwitch($productSwitch);
            $productSwitch.API = productSwitchAPI;
        }
    }
});
